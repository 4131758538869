.App {
  text-align: center;
  font-family: Avenir;
  overflow: scroll;
}

.upload, .signin_page {
  text-align: center;
  font-family: Avenir;
  display: inline-block;
  width: 100%;
  z-index: 1;
}

.submit, .upload-btn {
  padding: 4px;
}

.logo {
  height: 10%;
  width: 15%;
  float: left;
  /* position: absolute; */
}

.logo_signin {
  height: 10%;
  width: 15%;
}

.input_fastq, .input_seq, .input_client, .input_add {
  display: none;
}

.file-inputs {
  margin-top: 10%;
  align-items: center;
}

.selector_drop {
  display: flex;
  place-content: center;
}

.selector {
  margin-right: 10px;
}

.upload-btn, .template-btn, .hosp_temp {
  margin-right: 10px;
}

.title {
  font-weight: bold;
}

.helpful {
  font-size: 12px;
  font-family: Avenir;
}

.footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
  padding: 1rem;
  /* background-color: white; */
}

.support {
  /* float: right;
  bottom: -10px; */
  bottom: 1%;
  right: 0%;
  position: fixed;
  cursor: pointer;
  z-index: 5;
}
